import {
  NO_PREVUOUS_JOB_CUSTOMER,
  NO_PREVUOUS_JOB_TECH,
} from "constants/expandableJobContants";
import { Col, Button } from "react-bootstrap";
import Box from "../../common/Box";
import React,{useState,useEffect} from "react";
import { checkPendingStatus } from "utils";
import mixpanel from "mixpanel-browser";
import { klaviyoTrack } from "api/typeService.api";
import { isMobile, isTablet } from "react-device-detect";
import {APEX_ORG_NAME,APEX_OWNER_EMAIL,APEX_OWNER_OWNERID} from "../../../constants/index";
import { openNotificationWithIcon } from "../../../utils";
import { useSocket } from "../../../context/socketContext";
import { Modal } from "antd";
import {  useLocation } from "react-router";

  


const EmptyDashboard = ({ userType,user }) => {
  const [isEmailVerify, setIsEmailVerify] = useState(false);
  const { socket } = useSocket();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const org_id = queryParams.get("org") ;
  if (org_id) {
    localStorage.setItem('org_id', org_id);
  }
  /**
	 * Function will check if there are any pending jobs of the customer.if pending then open modal and run push_to_profile_setup function
	 * @author : Nafees
	 */
  const push_to_profile_setup = async (e) => {

    console.log('>>>>>>>>>>>>push_to_profile_setup ::::::: ');
    if(user){
      const storedOrgId = localStorage.getItem('org_id');
      const emailDomain = user?.email?.split('@')[1];
      const apexOwnerEmail = user?.referred_code
      console.log("push_to_profile_setup",storedOrgId,emailDomain,apexOwnerEmail);

      if (
        apexOwnerEmail == APEX_OWNER_EMAIL ||
        storedOrgId == APEX_ORG_NAME ||
        (emailDomain && emailDomain.includes(APEX_ORG_NAME)) ||
        user?.ownerId === APEX_OWNER_OWNERID
      ){
      console.log("push_to_profile_setup 1 ",user?.verified);

          
        if(!user?.verified){
            setIsEmailVerify(true);
            return ;
          }
      }
    }

    if (user) {
      mixpanel.identify(user?.email);
      mixpanel.track('Customer - Post a job');
      mixpanel.people.set({
        $first_name:user?.firstName,
        $last_name:user?.lastName,
      });
    }
    if (window.localStorage.getItem('extraMin')) {
      window.localStorage.removeItem('extraMin');
    }
    if (window.localStorage.getItem('secs')) {
      window.localStorage.removeItem('secs');
    }
    
    if (user.customer?.customerType === 'live') {
      let partnerName = '';
      let partnerStatus = false;
      if (user && user?.partner) {
        partnerName = user?.partner;
        partnerStatus = true;
      }
      const klaviyoData = {
        email:user?.email,
        event: 'Job Post Button Click',
        properties: {
          $first_name:user?.firstName,
          $last_name:user?.lastName,
          $partnerName: partnerName,
          $partnerStatus: partnerStatus,
        },
      };
      await klaviyoTrack(klaviyoData);
    };
    if (isMobile || isTablet) {
      window.location.href = "/post-job";
    }else{
      window.location.href = '/customer/profile-setup?page=select-software';
    }
  };
  const closeEmailVerify = () => {
    setIsEmailVerify(false);
    
  };
  const verifyEmailForOrganization = () => {
    socket.emit("verifyEmailForOrganization");
    localStorage.removeItem('org_id');
    setIsEmailVerify(false);
  };

  
  return (
    <div>
      {userType == "customer" ? (
        <Col span={24} align="middle">
          <Box>
            <div className="divarea">
              <p>{NO_PREVUOUS_JOB_CUSTOMER}</p>
              <Button
                key={"btn-post-job"}
                disabled={false}
                onClick={push_to_profile_setup}
                className="btn app-btn"
              >
                <span />
                Get Help Now 
              </Button>
            </div>
          </Box>
        </Col>
      ) : (
        <Col span={24} align="middle">
          <Box>
            <div className="divarea">
              <p>{NO_PREVUOUS_JOB_TECH}</p>
            </div>
          </Box>
        </Col>
      )}
       <Modal
        className="get-help-now-modal"
        closable={false}
        onCancel={closeEmailVerify}
        visible={isEmailVerify}
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        maskClosable={true}
        width={705}
        footer={[
          <div className="modal-flex-get-help-now" style={{marginRight:'13px'}}>
            

            <Button
              id="confirm-create-new"
              className="btn app-btn job-accept-btn modal-footer-btn"
              onClick={verifyEmailForOrganization}
              key="yes"
            >
              <span></span>Resend Email
            </Button>
            <Button
              className="btn app-btn app-btn-light-blue modal-footer-btn"
              onClick={() => {
                setIsEmailVerify(false);
              }}
              key="no"
            >
              <span></span>Cancel
            </Button>
          </div>,
        ]}
      >
        <div className="">
          <span
            className="div-font"
            style={{ fontSize: 20, paddingTop: "40px" }}
          >
            Please verify your account. Click below verify to resend Verification Email.
          </span>
        </div>
      </Modal>
    </div>
  );
};

export default EmptyDashboard;
