import Button from "@mui/material/Button";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState, useRef } from "react";
import checBoxInCricle from "../../../../../assets/images/Checkbox-cyan.png";
import logos from "../../../../../assets/images/Logos bottom.png";
import crossIcon from "../../../../../assets/images/cross-icon.png";
import cardSample from "../../../../../assets/images/credit-card-stripe.png";
import infoIcon from "../../../../../assets/images/info_icon.png";
import Heading from "../components/Heading";
import SubHeading from "../components/SubHeading";
import PromoCodePopUp from "../components/PromoCodePopUp";
import IconButton from "@mui/material/IconButton";
import * as UserApi from "../../../../../api/users.api";
import * as CustomerApi from "../../../../../api/customers.api";
import * as JobApi from "../../../../../api/job.api";
import * as PromocodeApi from "../../../../../api/promoCode.api";
import {
  openNotificationWithIcon,
  GAevent,
  ifJobStatusIsNotDraft,
} from "utils";
import {JobTags, JOB_STATUS, PREAUTH_BTN_TITLE } from "../../../../../constants";
import mixpanel from "mixpanel-browser";
import { useAuth } from "context/authContext";
import { useJob } from "context/jobContext";
// import { useNotifications } from "context/notificationContext";
// import { useSocket } from "context/socketContext";
import { Spin } from "antd";
import { useLocation } from "react-router";
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import { Modal } from "antd";
import { Row } from "react-bootstrap";
import BasicButton from "../../../components/Buttons/ButtonWithIcon";
import * as JobCycleApi from "../../../../../api/jobCycle.api";

const AddCard = ({
  userId,
  jobIdFromUrl,
  isScheduleJob,
  setMobileSignUpStepNumber,
  signUpFlowStepsObj,
  techIdFromUrl,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userIdFromUrl = queryParams.get("userId")
    ? queryParams.get("userId")
    : false;
  const elements = useElements();
  const [zipcode, setZipcode] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [user, setUser] = useState(false);
  const { refetch } = useAuth();
  const stripe = useStripe();
  const { updateJob, job, fetchJob } = useJob();
  // const { createNotification, fetchNotifications } = useNotifications();
  // const { socket } = useSocket();
  const [open, setOpen] = useState(false);
  const [promoId, setPromoId] = useState("");
  const [promoCodeDetails, setPromoCodeDetails] = useState({});
  const [promoCodeAppliedSuccessfully, setPromocodeAppliedSuccessfully] =
    useState(false);
  const [referralEmailAddress, setreferralEmailAddress] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const cardNumberRef = useRef(null);
  const cardExpiryRef = useRef(null);
  const cardCvcRef = useRef(null);
  const nextElement = useRef(null);
  const [promoCodeResponseMethod, setPromoCodeResponseMethod] = useState("");
  const [cardDetailsFilled,setCardDetailsFilled]= useState({
    cardNumber : false,
    expiryDate : false,
    cvc : false,
    zip : false
  })
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (jobIdFromUrl) {
      ifJobStatusIsNotDraft(jobIdFromUrl, "/");
      fetchJob(jobIdFromUrl);
    }

    if (userIdFromUrl) {
      getUser(userIdFromUrl);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (job && job.customer.user.id) {
        // console.log("Looking for job at addcard page", { job });
        // console.log("UserId AddCard page", { userID: job.customer.user.id });
        getUser(job.customer.user.id);
      }
    })();
  }, [job]);

  const getUser = async (userId) => {
    const fetchedUserDetails = await UserApi.getUserById(userId);
    if (fetchedUserDetails) {
      console.log("user at Addcard page");
      setUser(fetchedUserDetails);
    }
  };

  const updatePromoCodeDetails = async (promoCodeDetails, jobId) => {
    try {
      // console.log("Checking Parsed Data is Available--", promoCodeDetails);
      if (promoCodeDetails && promoCodeDetails.id) {
        const updateData = {
          user_id: user && user.id,
          job_id: jobId,
          used_date: new Date(),
        };
        // console.log("updateData", updateData);
        // updating promocode details to db
        const updateResponse = await PromocodeApi.updatePromoData(
          promoCodeDetails.id,
          updateData
        );
        if (updateResponse) {
          const updateUser = {
            coupon_id: promoCodeDetails.id,
            coupon_code: promoCodeDetails.promo_code,
            discount_type: promoCodeDetails.discount_type,
            coupon_code_discount: promoCodeDetails.discount_value,
          };
          await updateJob(jobId, updateUser);
          return;
        }
      }
    } catch (error) {
      console.error(
        "updating job details with promocode if applied  : error ",
        error
      );
    }
  };

  async function checkCardAndAddCardToCustomer(
    cardElement,
    data,
    payload,
    newCustomer,
    stripe_customer_id
  ) {
    let updatedCustomer;
    if (newCustomer) {
      // console.log("addCardForm handleSubmit createCustomerStripe", user);
      // creating customer's stripe id
      const result_customer = await CustomerApi.createCustomerStripe({
        email: user.email,
        liveUser: user.customer.customerType === "live",
      });

      // console.log("addCardForm handleSubmit createCustomerStripe 2", {
      //   result_customer,
      // });

      var customer_id = result_customer.id;
      // updating customers strip id in DB
      updatedCustomer = await CustomerApi.updateCustomer(user.customer.id, {
        stripe_id: customer_id,
      });
      stripe_customer_id = customer_id;
      // console.log("addCardForm handleSubmit createCustomerStripe 3", {
      //   updatedCustomer,
      // });
      if (job && job.id) {
        // updating job.id
        JobApi.updateJob(job.id, { tech_search_start_at: new Date() });
      }
    }
    //adding card to customer's strip.id
    let result_card = await CustomerApi.addCardToCustomerStripe({
      liveUser: user?.customer?.customerType === "live",
      stripe_id: stripe_customer_id,
      token_id: payload.token.id,
      // planId: planId ? planId : "NA",
      jobId: job && job.id ? job.id : "NA",
    });
    // console.log("addCardForm handleSubmit createCustomerStripe 4", {
    //   result_card,
    // });

    if (result_card["error"] != undefined) {
      setDisableBtn(false);
      openNotificationWithIcon(
        "error",
        "Error",
        result_card["error"]["message"]
      );
      // mixpanel code//
      mixpanel.identify(user.email);
      mixpanel.track("Customer - Card not added due to some error in card.");
      // mixpanel code//
    } else {
      // Making the new card as default card
      if (result_card["id"]) {
        await CustomerApi.updateDefaultCard({
          liveUser: user?.customer?.customerType === "live",
          card_id: result_card["id"],
          customer_id: stripe_customer_id,
        });
      }

      openNotificationWithIcon(
        "success",
        "Success",
        "Card details has been saved."
      );
      // mixpanel code//
      mixpanel.identify(user.email);
      mixpanel.track("Customer - Card details added.");
      // mixpanel code//

      const custObj = {
        stripe_id: stripe_customer_id,
        liveUser: user?.customer?.customerType === "live",
        jobId: "NA",
      };
      const holdPaymentStatus = await paymentHoldProcess(custObj);
      if (!holdPaymentStatus) {
        setDisableBtn(false);
        return;
      } else {
        // Moving on to next screen
        setMobileSignUpStepNumber(signUpFlowStepsObj["PhoneNumber"])
        if (jobIdFromUrl) {
          if (techIdFromUrl) {
            window.location.href = `mobile?jobId=${jobIdFromUrl}&page=PhoneNumber&technicianId=${techIdFromUrl}`;
          } else {
            window.location.href = `mobile?jobId=${jobIdFromUrl}&page=PhoneNumber`;
          }
        } else {
          window.location.href = `mobile?userId=${userIdFromUrl}&page=PhoneNumber`;
        }
      }
    }
  }

      // This function is used to refund the holded amount to the customer back after authorization
      const refundHoldedMoney = async (holdChargeResponse) => {
        // This will refund the hold money from customer account
        if (
          holdChargeResponse?.payment_id &&
          holdChargeResponse?.payment_status == "requires_capture"
        ) {
          const obj = {
            payment_hold_id: holdChargeResponse?.payment_id,
            isDeduct: false,
            jobId: "NA",
            stripe_id: holdChargeResponse?.stripe_id,
          };
          const cancelledResponse = await CustomerApi.deductOrRefundHoldMoney(obj);
          // mixpanel code//
          mixpanel.identify(user.email);
          mixpanel.track("Customer - Refund $100 from customer", cancelledResponse);
          // mixpanel code//
          return cancelledResponse;
        }
      };

  // This function will remove the card if it fails to hold the amount
  const removeCard = async (card_id, stripe_id,liveUser) => {
    const response = await CustomerApi.removeCard({
      liveUser: liveUser,
      card_id: card_id,
      customer_id: stripe_id,
    });
    return response;
  };

  // This function is used to check if payment 
  const paymentHoldProcess = async (custObj) => {
    try {
      const holdChargeResponse =
        await CustomerApi.holdChargeFromCustomer(custObj);
      if (holdChargeResponse.status !== "Successful") {
        if(jobIdFromUrl){
          await JobCycleApi.create(JobTags.HOLD_CHARGE_FAIL, jobIdFromUrl);
        }
        const card_id = holdChargeResponse?.response?.source;
        const stripe_id = holdChargeResponse?.response?.customer;
        if (card_id && stripe_id) {
          await removeCard(card_id, stripe_id, custObj?.liveUser);
        } else {
          const card_id = holdChargeResponse?.response?.source?.id;
          const stripe_id =
            holdChargeResponse?.response?.payment_intent?.customer;
          await removeCard(card_id, stripe_id, custObj?.liveUser);
        }
        openNotificationWithIcon("error", "Error", holdChargeResponse.message);
        return false
      } else {
          await refundHoldedMoney(holdChargeResponse);
          return true
      }
    } catch (error) {
      openNotificationWithIcon("error", "Error", "Something went wrong. Please try again.");
      return false
    }
  };

  const addCard = async () => {
    console.log("Add card clicked");
    setDisableBtn(true);
    // console.log("looking for jobIdFromUrl && promoCodeAppliedSuccessfully", {
    //   jobIdFromUrl,
    //   promoCodeAppliedSuccessfully,
    // });
    if (jobIdFromUrl && promoCodeAppliedSuccessfully) {
      await updatePromoCodeDetails(promoCodeDetails, jobIdFromUrl);
    }
    const cardElement = elements.getElement(CardNumberElement);
    var dataToStripe = {};
    dataToStripe["metadata"] = { zip: zipcode };
    if (!stripe || !elements) {
      setDisableBtn(false);
      return;
    }
    stripe.createToken(cardElement, dataToStripe).then(async (payload) => {
      // console.log("payload>>>>>>>>>>>>>>>>>>>>", payload);
      if (payload["error"]) {
        setDisableBtn(false);
        openNotificationWithIcon("error", "Error", payload["error"]["message"]);
        return;
      } else {
        if (zipcode === "") {
          setDisableBtn(false);
          openNotificationWithIcon(
            "error",
            "Error",
            "Zip cannot be left empty"
          );
          return;
        } else {
          // console.log("Looking for user", user);
          // await UserApi.updateUser({ userId: job.customer.user.id, zip: zipcode, referred_code:referralEmailAddress })
          await UserApi.updateUser({
            userId: user.id,
            zip: zipcode,
            referred_code: referralEmailAddress,
          });
        }
        // retrieve customer's strip id to Db
        // let retrieve_cust = await CustomerApi.retrieveCustomer(job.customer.id);
        let retrieve_cust = await CustomerApi.retrieveCustomer(
          user?.customer?.id
        );
        // console.log("addCardForm handleSubmit retrieve_cust::", retrieve_cust);
        if (
          !retrieve_cust.stripe_id ||
          retrieve_cust.stripe_id === "" ||
          retrieve_cust.stripe_id == null
        ) {
          checkCardAndAddCardToCustomer(
            cardElement,
            dataToStripe,
            payload,
            true,
            false
          );
        } else {
          checkCardAndAddCardToCustomer(
            cardElement,
            dataToStripe,
            payload,
            false,
            retrieve_cust.stripe_id
          );
        }
      }
    });
  };

  const handleCloseAuth = () => {
    setTimeout(() => {
      setIsInputFocused(false);
    }, 100);
  };

  const handleInputBlur = () => {
    setIsInputFocused(true);
  };

  const handleCardNumberElement = (event) => {
    // Your existing card number element handling logic

    if (event.complete) {
      setCardDetailsFilled((prevData) => ({ ...prevData, cardNumber : true }));
      // Move focus to the next element (card expiry)
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      if (cardExpiryElement) {
        cardExpiryElement.focus();
      }
    }
  };

  const handleCardExpiryElement = (event) => {
    // Your existing card expiry element handling logic
    setCardDetailsFilled((prevData) => ({ ...prevData, expiryDate : true }));
    if (event.complete) {
      // Move focus to the next element (card cvc)
      const cardCvcElement = elements.getElement(CardCvcElement);
      if (cardCvcElement) {
        cardCvcElement.focus();
      }
    }
  };

  const handleCardCvcElement = (event) => {
    // Your existing card cvc element handling logic
    setCardDetailsFilled((prevData) => ({ ...prevData, cvc : true }));
    if (event.complete) {
      // Move focus to the next element (e.g., another card element or any other input)
      if (nextElement.current) {
        nextElement.current.focus();
      }
    }
  };

  const fillZipCodeDetails = (e)=>{
    setZipcode(e.target.value)
    setCardDetailsFilled((prevData) => ({ ...prevData, zip : true }));
  }
  
  const result = promoCodeResponseMethod
    ? " {promo code} = "
    : promoId && referralEmailAddress
    ? "{Promo Code/Referral} ="
    : promoId
    ? " {promo code} = "
    : referralEmailAddress
    ? " {Referral email} = "
    : " Promo Code/Referral";

  const resultToShow = promoCodeResponseMethod
    ? "promo code not applicable"
    : promoId && referralEmailAddress
    ? `${promoId} / ${referralEmailAddress}`
    : promoId
    ? `${promoId}`
    : referralEmailAddress
    ? referralEmailAddress
    : "";
  return (
    <>
    <Modal
        visible={isModalOpen}
        closable={false}
        centered
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        onOk={false}
        footer={null}
      >
        <div className="card-info-main-div">
        <Row>
          <span className="card-info-content">
            Your credit card will be "pre-authorizated" an estimated amount
            which is about the average of your job. This pre-authorization
            confirms that the funds are available on your credit card and puts a
            "hold" on those funds. You are NOT charged this amount, final cost
            is based on call time and may be more or less.
          </span>
        </Row>
        <Row className="d-flex justify-content-end mt-2">
          <BasicButton
            id="pop-up-modal"
            btnTitle={"OK"}
            height={"40px"}
            width={"55px"}
            background={"#01D4D5"}
            color={"white"}
            faFontSize={"18px"}
            onClick={()=>setIsModalOpen(false)}
          />
        </Row>
        </div>
      </Modal>
      {/* Modal for promo code starts */}
      <PromoCodePopUp
        open={open}
        setOpen={setOpen}
        promoId={promoId}
        setPromoId={setPromoId}
        setPromoCodeDetails={setPromoCodeDetails}
        promoCodeAppliedSuccessfully={promoCodeAppliedSuccessfully}
        setPromocodeAppliedSuccessfully={setPromocodeAppliedSuccessfully}
        user={user}
        referralEmailAddress={referralEmailAddress}
        setreferralEmailAddress={setreferralEmailAddress}
        promoCodeResponseMethod={promoCodeResponseMethod}
        setPromoCodeResponseMethod={setPromoCodeResponseMethod}
      />
      {/* Modal for promo code ends */}

      <div className="headingDiv pl-20-max-width-280 pr-20-max-width-280">
        <div className="d-flex flex-column heading-div sub-heading-style">
          <Heading text={"Enter Card Details"} />
          <SubHeading
            text={
              "You won't be billed for services until you start a call. This step ensures your card is valid so you can get help."
            }
          />
        </div>
        <div className="card-sample-container">
          <img src={cardSample} className="card-sample-style" />
        </div>
        <div className="card-detail-container">
          {jobIdFromUrl && (
            <div className="promo-code-container">
              {promoCodeAppliedSuccessfully ? (
                <>
                  <img src={checBoxInCricle} className="check-icon-style" />
                  <IconButton disableRipple className="p-0">
                    <span className="add-card-promo-code-text font-nova">
                      <span className="font-nova" onClick={() => setOpen(true)}>
                        {result}
                      </span>
                    </span>
                   
                  </IconButton>
                  <span className="font-nova signUpSubHeading">
                    {resultToShow}
                  </span>
                  
                </>
              ) : (
                <>
                  <span
                    onClick={() => setOpen(true)}
                    className="add-card-promo-code-text font-nova"
                  >
                    {result}
                  </span>
                  <span className="font-nova signUpSubHeading">
                    {resultToShow}
                  </span>
                </>
              )}
            </div>
          )}
          <div className="main-div">
            <div className="inner-div">
              <CardNumberElement
                className="w-100 font-nova"
                options={{ placeholder: "Card Number" }}
                onBlur={handleInputBlur}
                onChange={handleCardNumberElement}
                ref={cardNumberRef}
              />

              <div className="inner-div-cvv">
                <CardExpiryElement
                  className="stripe-card-style font-nova bodr-r"
                  options={{ placeholder: "MM / YY" }}
                  disabled={true}
                  ref={cardExpiryRef}
                  onChange={handleCardExpiryElement}
                />

                <CardCvcElement
                  className="stripe-card-style font-nova bodr-r width-50px"
                  options={{ placeholder: "CVC" }}
                  ref={cardCvcRef}
                  onChange={handleCardCvcElement}
                />

                <input
                  ref={nextElement}
                  type="text"
                  placeholder="ZIP"
                  className="zip-input font-nova"
                  onChange={(e) => fillZipCodeDetails(e)}
                  value={zipcode}
                />
              </div>
            </div>
          </div>
          {(cardDetailsFilled.cardNumber && cardDetailsFilled.expiryDate && cardDetailsFilled.cvc && cardDetailsFilled.zip) && 
          <>
          <Button
            variant="contained"
            className="contained-Verify-Card-btn"
            onClick={addCard}
            disabled={disableBtn}
          >
            {disableBtn ? (
              <Spin className="spinner spinner-pos" />
            ) : (
              <span className="contained-Verify-Card-btn-text font-nova">
                Next
              </span>
            )}
          </Button>
         
          </> }
          <div className="d-flex align-items-center justify-content-between text-left mt-mb-6">
            <img src={infoIcon} className="insurance-info-icon mr-12" />
            <span className="insurance-text font-nova">
             *You only pay for call time. Up to 5 minute free assessment <br/> window. Your card will show a pre-authorization &nbsp;
             <QuestionMarkOutlinedIcon
                className="question_mark"
                onClick={()=>setIsModalOpen(true)}
              />
            </span>
          </div>
          {isInputFocused && (
            <div className="authorization-div">
              <div className="authorization-inner-div">
                <span className="authorization-div-text font-nova">
                  You may see a temporary pre-authorization “hold” charge on you
                  card. This is used to verify the card and will be released
                  within 2 days
                </span>
              </div>
              <div className="croxx-icon">
                <img
                  src={crossIcon}
                  onClick={handleCloseAuth}
                  className="croxx-icon-style"
                />
              </div>
            </div>
          )}
        </div>
        <div className="stripe-partners">
          <img src={logos} />
        </div>
      </div>
    </>
  );
};

export default AddCard;
