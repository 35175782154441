import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { Spin } from "antd";
import { klaviyoTrack } from "api/typeService.api";
import { useNotifications } from "context/notificationContext";
import { useSocket } from "context/socketContext";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import DatePicker from "react-mobile-datepicker";
import { useJob } from "../../../../../context/jobContext";
import {
  checkCustomerHaveSubscriptionMinutes,
  checkCustomerSubscription,
  checkOwnerStripeId,
  checkPendingStatusOfSchedule,
  openNotificationWithIcon,
} from "utils";
import * as CustomerApi from "../../../../../api/customers.api";
import * as JobApi from "../../../../../api/job.api";
import * as JobCycleApi from "../../../../../api/jobCycle.api";
import * as UserApi from "../../../../../api/users.api";
import { JobTags, dateConfig, JOB_STATUS,MESSAGES } from "../../../../../constants";
import { decideMeetingServiceViaLaunchdarkly, scheduleJobTimeToLocalTimeZone,checkAndHandleTimeCollision ,getPrimaryTime} from "../../../../../utils";
import Heading from "../components/Heading";
import Loader from '../../../../../components/Loader';
import SubHeading from '../components/SubHeading';
import ScheduleJobModal from 'components/ScheduleJobModal';
import * as TwilioApi from '../../../../../api/twilioChat.api'
import { ReactSVG } from "react-svg";
import Globe from "../../../../../assets/images/globe.svg";
import * as PromocodeApi from "../../../../../api/promoCode.api";


const ScheduleJob = ({
  jobIdFromUrl,
  setMobileSignUpStepNumber,
  reScheduleTime,
  setReScheduleTime,
  selectedScheduleTime,
  setSelectedScheduleTime,
  techIdFromUrl,
  user,
  isJobReShedule
}) => {
  const firstFreePromoCode = localStorage.getItem("firstFreePromoCode");
  const [promoCodeInput, setPromoCodeInput] = useState(firstFreePromoCode || "");
  const [promoCodeApplied, setIsPromocodeApplied] = useState({});
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [createJobStatus, setCreateJobStatus] = useState(false);
  const [scheduleJobTimeLoader, setscheduleJobTimeLoader] = useState(true)
  const [userInfo, setUserInfo] = useState()
  const [lastPendingSoftware, setLastPendingSoftware] = useState("");
  const [customerConfirm, setCustomerConfirm] = useState(false);
  const { updateJob } = useJob();
  const [scheduleMsg, setScheduleMsg] = useState(true);
  const [disableBackToDashbord, setDisableBackToDashbord] = useState(false);
  const [jobInfo, setJobInfo] = useState()
  const [jobIdDecline, setJobIdDecline] = useState(false);
  const [scheduleJobTime, setScheduleJobTime] = useState()
  const { createNotification, fetchNotifications } = useNotifications();
  const { socket } = useSocket();
  const [alertType, setAlertType] = useState('success')
  const [alertTypeForInfo, setAlertTypeForInfo] = useState('info')
  const [selectedDateAndTime, setSelectedDateAndTime] = useState()
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false)
  const [isRescheduled, setIsRescheduled] = useState(false)
  const [selectedTimeForReSchedule, setSelectedTimeForReSchedule] = useState(false)
  const [localScheduleJobTime, setLocalScheduleJobTime] = useState("")
  const message = scheduleMsg ? (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
     you already have 4 scheduled job posted , to proceed further your last posted job will be cancelled. 
    </span>
  ) : (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      We are still looking for a technician for your existing job of{" "}
      <b style={{ fontWeight: "bold" }}>{lastPendingSoftware}</b>. Are you sure
      you want to create a new job post? if yes, then your previous job will be{" "}
      <b style={{ fontWeight: "bold" }}>Cancelled</b>
    </span>
  );
  
  useEffect(() => {
    (async () => {
      if (jobIdFromUrl) {
        // console.log(`Job ${jobIdFromUrl}`);

        const jobData = await JobApi.retrieveJob(jobIdFromUrl);

        if (jobData) {
          // Checking if job status is draft or not
          if (
            jobData.status !== JOB_STATUS.DRAFT &&
            jobData.status !== JOB_STATUS.SCHEDULED
          ) {
            window.location.href = "/";
          }

          if (jobData.primarySchedule) {
            setSelectedDateAndTime(
              new Date(jobData.primarySchedule).toString().split(" ")
            );
            setScheduleJobTime(new Date(jobData.primarySchedule));
            const scheduleJobTimeInLocalTimeZone = scheduleJobTimeToLocalTimeZone(new Date(jobData.primarySchedule), jobData.customer.timezone)
            // console.log("debugging timezone issue 1", {dateAndTime:jobData.primarySchedule, scheduleJobTimeInLocalTimeZone})
            setLocalScheduleJobTime(scheduleJobTimeInLocalTimeZone.scheduleJonTimeToDisplay)
            setscheduleJobTimeLoader(false);
          } else {
            setscheduleJobTimeLoader(false);
          }

          // console.log("looking for jobData", { jobData });
          setJobInfo(jobData);
          if (jobData.customer.user.id) {
            const userData = await UserApi.getUserById(
              jobData.customer.user.id
            );
            if (userData) {
              // console.log("looking for userData", { userData });
              setUserInfo(userData);
            }
          }
        }
      }
    })();
  }, []);

  useEffect(() => {
    async function applyPromoCodeBasedOnStorage() {
      let promoCodeToUse =  firstFreePromoCode;
      if (promoCodeToUse) {
        const response = await PromocodeApi.retrievePromoData(promoCodeToUse);
      
        if (response && response.used_by && response.promocode_status !== "expired") {
          if (user && user.id) {
            if (!user?.customer?.subscription) {
              const findUser = response.used_by.find(
                (item) => item.user_id === user.id
              );
              if (!findUser) {
                // setPartnerFreeJob(true);
                // setHavePromoCode(true);
                await applyPromoCode();
              }
            }
          } else {
            // setPartnerFreeJob(true);
            // setHavePromoCode(true);
            await applyPromoCode();
          }
        }
      }
    }
  
    applyPromoCodeBasedOnStorage();
  }, [ firstFreePromoCode]);

  // Promo Code Apply Function
  const applyPromoCode = async () => {
    if (promoCodeInput) {
      setIsPromocodeApplied({});
      const promocode = promoCodeInput.trim();
      // retrieving promocode data to db
      const response = await PromocodeApi.retrievePromoData(promocode);
      if (response) {
        if (response && response.message) {
          return openNotificationWithIcon("error", "Error", response.message);
        }
        if (
          response &&
          response.expiry_date &&
          new Date(response.expiry_date).setHours(0, 0, 0, 0) <
            new Date().setHours(0, 0, 0, 0)
        ) {
          return openNotificationWithIcon("error", "Error", MESSAGES[0]);
        }
        if (response && response.used_by) {
          if (user && user.id) {
            const findUser = response.used_by.find(
              (item) => item.user_id === user.id
            );
            // console.log("Check User Exists ?", findUser);
            if (findUser && !(JSON.stringify(findUser) === "{}")) {
              return openNotificationWithIcon("error", "Error", MESSAGES[1]);
            } else {
              setIsPromocodeApplied(response);
              // setPromoApplied(true);
              const message =
                response.discount_type === "fixed"
                  ? `Promocode of $${response.discount_value} is applied`
                  : `Promocode of ${response.discount_value}% is applied`;
              // setPromoCodeValue(response.discount_type === "fixed" ? '$' + response.discount_value : response.discount_value + "%");
              return openNotificationWithIcon("success", "Success", message);
            }
          } else {
            setIsPromocodeApplied(response);
            // setPromoApplied(true);
            const message =
              response.discount_type === "fixed"
                ? `Promocode of $${response.discount_value} is applied`
                : `Promocode of ${response.discount_value}% is applied`;
            // setPromoCodeValue(response.discount_type === "fixed" ? '$' + response.discount_value : response.discount_value + "%");
            return openNotificationWithIcon("success", "Success", message);
          }
        }
      } else {
        return openNotificationWithIcon("error", "Error", MESSAGES[4]);
      }
    } else {
      return openNotificationWithIcon("error", "Error", MESSAGES[3]);
    }
  };
  useEffect(() => {
    (async () => {
      if (promoCodeInput && promoCodeApplied) {
        await updatePromoCodeDetails(jobInfo);
      }
    })();
  }, [promoCodeInput,promoCodeApplied]);

  // Update Details of User and JobID in Promocode Database
  const updatePromoCodeDetails = async (jobData) => {
    if (promoCodeApplied && promoCodeApplied.id) {
      const updateData = {
        user_id: user ? user.id : "",
        job_id: jobIdFromUrl,
        used_date: new Date(),
      };
      // console.log("updateData", updateData);
      // console.log("response-promo", promoCodeApplied);
      // updating promo code to the DB
      const updateResponse = await PromocodeApi.updatePromoData(
        promoCodeApplied.id,
        updateData
      );
      if (updateResponse) {
        const updateUser = {
          coupon_id: promoCodeApplied.id,
          coupon_code: promoCodeApplied.promo_code,
          discount_type: promoCodeApplied.discount_type,
          coupon_code_discount: promoCodeApplied.discount_value,
        }; 
        updateJob(jobIdFromUrl, updateUser);
        return true;
      }
    } 
  };

  useEffect(() => {
    setSelectedTimeForReSchedule(scheduleJobTime)

    let result = isWithin3Hours(jobInfo?.primarySchedule);
    if (jobInfo?.primarySchedule && jobInfo?.technician && jobInfo.schedule_accepted && result) {
      setIsRescheduled(true)
    }

  }, [jobInfo, selectedTimeForReSchedule, scheduleJobTime])

  const updateScheduleJob = async (job) => {

    const scheduleJobData = {}
    let scheduleDetails = {
      'primaryTimeAvailable': true,
      'primaryTimeExpiredAt': null,
      'secondaryTimeAvailable': false,
      'secondaryTimeExpiredAt': null,
      'scheduleExpired': false,
      'scheduleExpiredAt': null
    }

    const primaryTime = scheduleJobTime;
    scheduleJobData.primarySchedule = primaryTime;
    scheduleJobData.scheduleDetails = scheduleDetails
    scheduleJobData.scheduleDetails.scheduleExpiredAt = new Date(primaryTime - 300000)

    // console.log("job.technician ::: main", scheduleJobData)
    let oldTech = {}
    if (job?.technician) {
      // console.log("job.technician", job)
      let techNumber = ''
      if (job.technician && job.technician.profile && job.technician.profile.alertPreference && job.technician.profile.alertPreference.settings && job.technician.profile.alertPreference.settings.Job['Text']['toggle']) {
        techNumber = job.technician['profile']['alertPreference']['settings']['Job']['Text']['value']
      }
      if (job.customer.user.userType === 'customer' && job.technician) {
        // console.log("job.technician", job)

        scheduleJobData.technician = ''
        scheduleJobData.schedule_accepted = false
        scheduleJobData.status = 'Scheduled'
        scheduleJobData.tech_message_dashbord = false

        oldTech = {
          id: job.id,
          techId: job.technician['user'].id,
          firstName: job.technician['user'].firstName,
          email: job.technician['user'].email,
          timezone: job.technician['user']['timezone'],
          number: techNumber,
          by: "Customer " + job.customer.user.firstName
        }
        // console.log("oldTech ::: before", oldTech)
      }
      // console.log("jobStats :::", oldTech)
    }
    // console.log("scheduleJobData :::  0", scheduleJobData)
    if (job?.post_again_reference_technician) {
      scheduleJobData.chatRoomId = job.chatRoomId
    } else {
      scheduleJobData.chatRoomId = null
    }
    // console.log("scheduleJobData ::: 1", scheduleJobData)
    const jobStats = await JobApi.updateJob(job.id, scheduleJobData);
    // console.log("jobStats :::", jobStats)
    let custNumber = ''
    if (job.customer && job.customer.phoneNumber) {
      custNumber = job.customer.phoneNumber
    }

    if (job.customer.user.userType === 'customer') {
      // console.log("scheduleJobData :::  2", scheduleJobData)

      let title = "Job has been successfully updated. We are finding a technician for you. We will inform you when we find the technician."
      mixpanel.identify(job.customer.user.email);
      mixpanel.track('Job meeting time updated by customer', { 'JobId': job.id });
      if (job.technician) {
        // console.log("oldTech ::: after", oldTech)
        title = "Job has been successfully updated by customer and technician removed from job. We are finding a technician for you. We will inform you when we find the technician"
        await socket.emit('updated_schedule_job_accepted_technician_email', oldTech)
        await emitSocketCreateFetchNotification(oldTech.techId, oldTech.email, jobStats, "Meeting time of your accepted job with " + job.customer.user.firstName + " has been changed")
        if (oldTech.number) {
          await socket.emit('schedule_job_time_change_alert', oldTech)
        }
        mixpanel.track('Technician ' + oldTech.firstName + ' is removed from job.', { 'JobId': job.id });
      }
      await emitSocketCreateFetchNotification(job.customer.user.id, job.customer.user.email, jobStats, title)
      if (custNumber) {
        await socket.emit('schedule_job_time_change_alert', { id: job.id, number: custNumber, by: "Customer " + job.customer.user.firstName })
      }
      // call send-schedule-alerts socket from backend.
      // It will find available techs and send alerts by sms/email/notification
      // emit send-schedule-alerts socket
      socket.emit('search-for-tech', {
        jobId: jobStats.id,
        customerTimezone: job.customer.user.timezone,
        jobObj: jobStats,
        primaryTime,
        // secondryTime,
        phoneNumber: job.customer.phoneNumber,
        customerName: job.customer.user.firstName,
        customerEmail: job.customer.user.email,
        technicianId: (job && job?.post_again_reference_technician ? job.post_again_reference_technician : false),
      });
    }
    socket.emit("edit-job", job.id)
  }


  /**
   * emit send-schedule-alerts socket and create / fetch notification customer notifications
   * @params : jobStats(Type:Object): Have job details
   * @returns : null
   * @author : Ridhima Dhir
   */
  const emitSocketCreateFetchNotification = async (jobStats) => {
    try {
      // console.log("send-schedule-alerts :::::::::::", jobStats);
      //Notification for customer
      const notificationData = {
        user: userInfo.id,
        job: jobStats.id,
        read: false,
        actionable: false,
        title:
          "We are finding a technician for you. We will inform you when we find the technician",
        type: "Scheduled Job",
      };
      // console.log("notificationData ::::::::", notificationData);
      await createNotification(notificationData);
      console.log("fetchNotifications() :: called emitSocketCreateFetchNotification function in schedulejob.ts");
      await fetchNotifications({ user: userInfo.id });

      // call send-schedule-alerts socket from backend.
      // It will find available techs and send alerts by sms/email/notification
      let scheduleTimeNew = scheduleJobTime;
      socket.emit("search-for-tech", {
        jobId: jobStats.id,
        customerTimezone: userInfo.timezone,
        jobData: jobStats,
        primaryTime: scheduleTimeNew,
        phoneNumber: userInfo.customer.phoneNumber,
        customerName: userInfo.firstName,
        customerEmail: userInfo.email,
        technicianId: techIdFromUrl,
      });
    } catch (err) {
      mixpanel.identify(userInfo.email);
      mixpanel.track("There is catch error while create/fetch notification", {
        jobStats: jobStats,
        errMessage: err.message,
      });
      console.error(
        "There is catch error while create/fetch notification  :::: " +
        err.message
      );
    }
  };

  const handleSelect = async (dateAndTime) => {
    let scheduleTimeNew = dateAndTime;
    const scheduleTime = new Date(scheduleTimeNew).getTime();
    const currentTime = new Date().getTime();
    const scheduleJobTimeInLocalTimeZone = scheduleJobTimeToLocalTimeZone(dateAndTime, jobInfo.customer.timezone)
    // console.log("debugging timezone issue 2", {dateAndTime, scheduleJobTimeInLocalTimeZone})
    setLocalScheduleJobTime(scheduleJobTimeInLocalTimeZone.scheduleJonTimeToDisplay)

    const hourDifferenceFromNow = scheduleTime - currentTime;
    const isJobInWorkingHours = await handelIsJobInWorkingHours(dateAndTime);
    // console.log("Debugging for isJobInWorkingHours", { isJobInWorkingHours })
    if (isJobInWorkingHours) {
      setScheduleJobTime(dateAndTime)
      setSelectedDateAndTime(dateAndTime.toString().split(" "))
      setShowDatePicker(false)
      setAlertType('success')
      setAlertTypeForInfo('info')
    }
    if (hourDifferenceFromNow < 3600000) {
      openNotificationWithIcon("error", "Error", "Please select a time at least 1 hour from now!");
      setDisableButton(false);
      setAlertType("error")
      return;
    }
  }

  const isWithin3HoursForSchedule = async () => {
    console.log("isWithin3HoursForSchedule",isJobReShedule);
  
    let result = isWithin3Hours((isJobReShedule === 'true' && jobInfo?.technician) ? jobInfo?.primarySchedule :  selectedTimeForReSchedule);
    // let result = isWithin3Hours(selectedTimeForReSchedule);
    // console.log("isWithin3HoursForSchedule 2",result);
    if (result) {
      setAlertType('error')
      setOpenScheduleModal(true);
    } else {
      await handelScheduleForLater()
    }
  }


  const handelScheduleForLater = async () => {
    try {
      setIsDisabled(true)
      const isJobInWorkingHours = await handelIsJobInWorkingHours(scheduleJobTime);
      // console.log("Debugging for isJobInWorkingHours 2", { isJobInWorkingHours })
      setOpenScheduleModal(false);
      if (!jobInfo?.primarySchedule && isJobInWorkingHours) {
        await scheduleForLater();
      } else if (isJobReShedule === 'true') {
        await handelReScheduleTime();
      }

      setIsDisabled(false);
    } catch (error) {
      setIsDisabled(true)
      console.error("An error occurred: ", error);
    }
  }



  const isWorkingHours = (scheduleJobTime) => {
    const selectedDate = new Date(scheduleJobTime);
    // Convert the selected time to New York timezone
    const selectedDateInNewYork = new Date(
      selectedDate.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    const workingHoursStart = new Date(selectedDateInNewYork);
    workingHoursStart.setHours(9, 0, 0); // Start of working hours (9:00 AM)
    const workingHoursEnd = new Date(selectedDateInNewYork);
    workingHoursEnd.setHours(21, 0, 0); // End of working hours (9:00 PM)
    if (
      selectedDateInNewYork < workingHoursStart ||
      selectedDateInNewYork >= workingHoursEnd
    ) {
      return false;
    }
    return true;
  };

  // function isTimeMatchingSchedule(scheduleJobTime, formattedDates) {
  //   const date1WithTime = new Date(scheduleJobTime);
  //   for (const formattedDate of formattedDates) {
  //     const date2 = new Date(formattedDate);

  //     if (areDatesEqual(date1WithTime, date2)) {
  //       return true; // Match found
  //     }
  //   }
  //   return false; // No match found
  // }

  function areDatesEqual(date1, date2) {
    return date1.getTime() === date2.getTime();
  }

  function isWithin3Hours(givenTime) {
    // console.log('givenTime',new Date(givenTime) )
    const currentTime = new Date().getTime();
    // console.log("currentTime", currentTime);
    const parsedGivenTime = new Date(givenTime).getTime();
    // console.log("parsedGivenTime", parsedGivenTime);
    const timeDifference = parsedGivenTime - currentTime;
    // console.log("timeDifference", timeDifference);

    return Math.abs(timeDifference) <= 3 * 60 * 60 * 1000 || parsedGivenTime < currentTime;
  }


  const handelIsJobInWorkingHours = async (schJobDateTime) => {
    // const data = await JobApi.scheduleAcceptedJobs({
    //   customer: userInfo.customer.id,
    // });
    // const primaryScheduleTimes = data.primaryScheduleTimes;
    // const dateObjects = primaryScheduleTimes.map(
    //   (dateString) => new Date(dateString)
    // );
    // // console.log("debugging 1", { schJobDateTime });

    // const formattedDates = dateObjects.map((date) => {
    //   return date.toLocaleString("en-US", {
    //     year: "numeric",
    //     month: "numeric",
    //     day: "numeric",
    //     hour: "numeric",
    //     minute: "numeric",
    //     hour12: true,
    //   });
    // });

    // const isMatch = isTimeMatchingSchedule(schJobDateTime, formattedDates);
    // if (isMatch) {
    //   openNotificationWithIcon(
    //     "error",
    //     "Error",
    //     "One of your job is already scheduled with same date and time. Please pick any other time to schedule new job"
    //   );
    //   setAlertType("error");
    //   return false;
    // }
    const latestpendingJobs = await JobApi.latestpendingJobs({ "customer": user.customer.id });
    console.log('latest pending pob ::', latestpendingJobs)
    //setLatestPendingJobToUpdate(latestpendingJobs)
    const scheduleJobsArray = latestpendingJobs?.statusJobs?.find(entry => entry.status === 'Scheduled')?.jobs || [];
    const existingJobs = scheduleJobsArray;
   const hasCollisions= checkAndHandleTimeCollision(existingJobs,schJobDateTime)
   console.log("hascollisions",hasCollisions)
    if (hasCollisions) {
      openNotificationWithIcon('error', 'Error', "One of your job is already scheduled with same date and time. Please pick atleast one hour from  the previously scheduled job");
      return;
    } 
    // const isWithinWorkingHours = isWorkingHours(schJobDateTime);
    // if (!isWithinWorkingHours) {
    //   setAlertType('error')
    //   openNotificationWithIcon('error', 'Error', "Our techs are mostly available between 9am-9pm EST Mon-Fri. Please schedule a good time during these business hours. ");
    //   return false;
    // }

    setAlertType('success')
    return true;
  }


  // const handelReScheduleTime = async () => {
  //   console.log('inside handelReScheduleTime')
  //   setDisableButton(true);
  //   setIsDisabled(true)
  //   const updatedJob = await JobApi.updateJob(jobIdFromUrl, {
  //     primarySchedule: scheduleJobTime,
  //   });
  //   if (updatedJob) {
  //     setDisableButton(false);
  //     setIsDisabled(false);
  //     // setMobileSignUpStepNumber(6)
  //     window.location.href = `mobile?jobId=${jobIdFromUrl}&page=ScheduleJobSummary`;
  //   } else {
  //     setDisableButton(false);
  //     setIsDisabled(false);
  //     openNotificationWithIcon("info", "info", "error while updating time.");
  //     window.location.href = `/`;
  //   }
  // };

  const deducMoneyFromCustomer = async () => {
    try {
      // This will refund the hold money from customer account

      let ownerStripeId = '';
      const ownerId = jobInfo?.customer?.user?.ownerId;

      if (ownerId) {
        const ownerStripeRes = await UserApi.getUserById(ownerId);

        if (ownerStripeRes?.customer?.stripe_id) {
          ownerStripeId = ownerStripeRes?.customer?.stripe_id;
        }
      }

      const stripeId = ownerId ? ownerStripeId : jobInfo?.customer?.stripe_id;

      const obj = {
        payment_hold_id: "not-applicable-here",
        isDeduct: false,
        jobId: jobInfo?.id,
        stripe_id: stripeId
      };

      const obj2 = {
        jobId: jobInfo?.id,
        stripe_id: stripeId,
        isScheduleCancel: true,
      };

      let scheduleCancelResponse;

      await JobCycleApi.create(JobTags.CUSTOMER_CANCEL_ACCEPTED_SCHEDULE_JOB, jobInfo?.id);
      scheduleCancelResponse = await CustomerApi.deductCancelJobPayment(obj2);
      // console.log('scheduleCancelResponse :: :: 1', scheduleCancelResponse)
      // if (job && job?.customer_holded_payments && job?.customer_holded_payments.length > 0) {
      //     await CustomerApi.deductOrRefundHoldMoney(obj);
      //     console.log('scheduleCancelResponse :: :: 2', scheduleCancelResponse)
      // }

      if (scheduleCancelResponse?.status === 'Successful') {
        await JobCycleApi.create(JobTags.PAYMENT_DEDUCTED_FROM_CUSTOMER, jobInfo?.id);
        // console.log('scheduleCancelResponse :: :: 3', scheduleCancelResponse)
        openNotificationWithIcon('success', 'Success', 'Job has been updated.');
        return true;
      } else {
        // console.log('scheduleCancelResponse :: :: 4', scheduleCancelResponse)
        openNotificationWithIcon('error', 'Error', scheduleCancelResponse.message);
        setIsDisabled(false)
        return false;
      }
      // console.log('scheduleCancelResponse :: :: 4',scheduleCancelResponse)

    } catch (error) {
      setIsDisabled(false)
      // Handle the error appropriately, you can log it, show an error message, or take other actions.
      console.error('Error in deducMoneyFromCustomer:', error);
      // Optionally, rethrow the error if you want it to be caught by an outer try-catch block.
      throw error;
    }
  };

  const handelReScheduleTime = async () => {
    setDisableButton(true);

    try {
          let isInThreeHour = isWithin3Hours(jobInfo?.primarySchedule)
          // console.log('inside handelReScheduleTime :: :: 1', jobInfo?.primarySchedule)
          // console.log('inside handelReScheduleTime :: :: 2', isInThreeHour)
      if (jobInfo?.schedule_accepted && isInThreeHour) {
        let paymentResponse = await deducMoneyFromCustomer();
        // console.log('inside paymentResponse :: :: 1', paymentResponse);

        if (paymentResponse) {
          // console.log('inside paymentResponse :: :: 2', paymentResponse);
          await updateScheduleJob(jobInfo);
          await TwilioApi.updateTwilioConversation(jobInfo?.twilio_chat_service?.sid);
          socket.emit("refresh-ScheduleTime", jobInfo);
          window.location.href = `mobile?jobId=${jobIdFromUrl}&page=ScheduleJobSummary`;
        }
      } else {
        // console.log('inside paymentResponse :: :: 3');
        await updateScheduleJob(jobInfo);
        await TwilioApi.updateTwilioConversation(jobInfo?.twilio_chat_service?.sid);
        socket.emit("refresh-ScheduleTime", jobInfo);
        window.location.href = `mobile?jobId=${jobIdFromUrl}&page=ScheduleJobSummary`;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      openNotificationWithIcon("info", "info", "error while updating time.");
      window.location.href = `/`;
      // Handle the error as needed, e.g., show a user-friendly message
      // or perform additional actions.
    } finally {
      setDisableButton(false);
      // setIsDisabled(false);
    }
  };
  const isStripeAvailable=async()=>{
    const JobId = jobIdFromUrl;
    const isStripeAvilable = await checkOwnerStripeId(userInfo);
    // console.log("isStripeAvilable ::::", isStripeAvilable);
   
    if (isStripeAvilable.success) {
      let scheduleTimeNew = scheduleJobTime;

      const scheduleTime = new Date(scheduleTimeNew).getTime();
      const currentTime = new Date().getTime();

      const hourDifferenceFromNow = scheduleTime - currentTime;
      // console.log("hourDifferenceFromNow:", hourDifferenceFromNow);

      if (hourDifferenceFromNow < 3600000) {
        openNotificationWithIcon(
          "error",
          "Error",
          "Please select a time at least 1 hour from now!"
        );
        setDisableButton(false);
        setAlertType("error");
        setIsDisabled(false);
        return;
      } else {

        const scheduleJobData = {};
        let scheduleDetails = {
          primaryTimeAvailable: true,
          primaryTimeExpiredAt: null,
          secondaryTimeAvailable: false,
          secondaryTimeExpiredAt: null,
          scheduleExpired: false,
          scheduleExpiredAt: null,
        };
        scheduleJobData.scheduleDetails = scheduleDetails;
        scheduleJobData.scheduleDetails.scheduleExpiredAt = new Date(
          scheduleTimeNew - 300000
        );
        if (userInfo) {
          mixpanel.identify(userInfo.email);
          mixpanel.track(
            "Customer - Click on Schedule for later button from mobile view "
          );
        }
        let lifeCycleTag = "";
        lifeCycleTag = JobTags.SCHEDULE;
        await JobCycleApi.create(lifeCycleTag, false, userInfo.id);
        
          let partnerName = '';
          let partnerStatus = false;
          if (userInfo && userInfo?.partner) {
            partnerName = userInfo?.partner;
            partnerStatus = true;
          }
        const klaviyoData = {
          email: userInfo.email,
          event: "Scheduled Job Created",
          properties: {
            $first_name: userInfo.firstName,
            $last_name: userInfo.lastName,
            $partnerName: partnerName,
            $partnerStatus: partnerStatus,
          },
        };

        await klaviyoTrack(klaviyoData);
        if(firstFreePromoCode){
          scheduleJobData.primarySchedule = scheduleTimeNew;
          scheduleJobData.status = "Scheduled";
          const ownerId = userInfo?.ownerId ? userInfo?.ownerId : userInfo?.id;
          scheduleJobData.ownerId = ownerId;
          scheduleJobData.firstFreePromoCode = true
          scheduleJobData.jobType = userInfo?.customer?.customerType
          const updatedJob = await JobApi.updateJob(jobIdFromUrl, scheduleJobData);
          // console.log('updated job :::::', updatedJob)
          await emitSocketCreateFetchNotification(updatedJob)
          setDisableButton(false);
          // setMobileSignUpStepNumber(6)
          window.location.href = `mobile?jobId=${jobIdFromUrl}&page=ScheduleJobSummary`
          return jobInfo;
        }
        let customer_info = await checkCustomerSubscription(userInfo);
        if (customer_info.has_card_or_subscription == false && userInfo) {
          JobApi.updateJob(jobIdFromUrl, { primarySchedule: scheduleTimeNew });
          setDisableButton(false);
          setIsDisabled(false);
          openNotificationWithIcon("info", "Info", "No credit card found");
          window.location.href = `/`;
        } else {
          await JobCycleApi.create(JobTags.HAVE_CARD, JobId, userInfo.id);
          scheduleJobData.primarySchedule = scheduleTimeNew;
          scheduleJobData.status = "Scheduled";
          setScheduleJobTime((prevState) => ({
            ...prevState,
            date: scheduleTimeNew,
          }));
          await cardPreAuthorization(scheduleJobData);
        }
      }
    } else {
      openNotificationWithIcon("info", "Info", `Please add a credit card`);
      setIsDisabled(false);
      window.location.href = "/";
    }
  }
  const checkPendingJobs = async () => {
    console.log("checkPendingJobs")
    try {
    
   
      const latestpendingJobs = await JobApi.latestpendingJobs({
        customer: jobInfo.customer.id,
      });
      console.log("latestpendingJobs",latestpendingJobs)
      //setLatestPendingJobToUpdate(latestpendingJobs);

        let pendingJobs = await checkPendingStatusOfSchedule(jobInfo);
        console.log("pendingJobs",pendingJobs)
        // if (pendingJobs.schedule_accepted) {
        //   setScheduleMsg(true);
        // }
        let scheduleJobsArray = latestpendingJobs?.statusJobs?.find(entry => entry.status === 'Scheduled')?.jobs ;
               console.log("scheduleJobsArray",scheduleJobsArray)
               // lastPendingJob = scheduleJobsArray[0];
               if (scheduleJobsArray && scheduleJobsArray.length > 0) {
                // Access _id property of the first element
                setJobIdDecline(scheduleJobsArray[0]._id);
         
            }   
       // setJobIdDecline(latestpendingJobs.last_pending_job.id);
        if (pendingJobs.success) {
          setLastPendingSoftware(pendingJobs.name);
         
            setCustomerConfirm(true);
  
        } else {
          isStripeAvailable()
        }
      
    } catch (e) {
      console.log("Error in checkPendingJobs ", e);
    }
  };
  const scheduleForLater = async () => {
    console.log('inside scheduleForLater')
    setDisableButton(true);
    if (!scheduleJobTime) {
      setDisableButton(false);
      openNotificationWithIcon(
        "info",
        "info",
        "Please Pick date from calendar."
      );
      setAlertTypeForInfo("error");
      setAlertType("error");
      setIsDisabled(false);
      return;
    }
    checkPendingJobs();
    // Here we are checking is jobid is available in url then we are using that jobid otherwise we are using job.id
  
  };
  const backToDashBoard = async () => {
    setDisableBackToDashbord(true);
    const updateJobData = {
      cardPreAuthorization: false,
      customer: jobInfo?.customer?.id,
      guestJob: false,
    };
    await updateJob(jobIdFromUrl, updateJobData);
    window.location.href = "/dashboard";
  };
  const cardPreAuthorization = async (updateJobData, card_info = false) => {
    // Here we are checking is jobid is available in url then we are using that jobid otherwise we are using job.id
    const JobId = jobIdFromUrl ? jobIdFromUrl : jobInfo?.id;
    // console.log(
    //   "console to check client_id, session_id and _fbp",
    //   updateJobData,
    //   JobId
    // );
    // holdPaymentWhileSubscription variable check if there is any subscription purchased by customer owner and have subscription minutes to zero then we will charge to customer

    let holdPaymentWhileSubscription = false;
    if (jobInfo?.customer?.id) {
      holdPaymentWhileSubscription = await checkCustomerHaveSubscriptionMinutes(
        jobInfo?.customer
      );
    }

    // If customer have owner then we will update job with that id otherwise that's customer's user id will be set to that field
    const ownerId = userInfo?.ownerId ? userInfo?.ownerId : userInfo?.id;
    const ownerStripeStatus = await checkOwnerStripeId(userInfo);
    const stripeId =
      userInfo?.ownerId && ownerStripeStatus.success
        ? ownerStripeStatus.stripeId
        : jobInfo?.customer?.stripe_id;
    // console.log("ownerId & stripeId sch job", { ownerId, stripeId });
    let preAuthorization = {}
    if(holdPaymentWhileSubscription){
     preAuthorization = await CustomerApi.holdChargeFromCustomer({
      stripe_id: stripeId,
      liveUser: jobInfo?.customer?.customerType === "live" ? true : false,
      jobId: jobIdFromUrl,
    });
    }

    // console.log(
    //   "OrganizationTesting :: cardPreAuthorization logs to check 1",
    //   jobInfo
    // );


    if (preAuthorization?.status === "Successful" || !holdPaymentWhileSubscription) {
      updateJobData.cardPreAuthorization = true;
      updateJobData.payment_type =holdPaymentWhileSubscription ? "card_only" : "subscription_only";
      updateJobData.ownerId = ownerId;
      updateJobData.jobType = userInfo?.customer?.customerType
      const updatedJob = await JobApi.updateJob(jobIdFromUrl, updateJobData);
      // console.log('updated job :::::', updatedJob)
      await emitSocketCreateFetchNotification(updatedJob)
      setDisableButton(false);
      // setMobileSignUpStepNumber(6)
      window.location.href = `mobile?jobId=${jobIdFromUrl}&page=ScheduleJobSummary`
      return jobInfo
    } else {
      setDisableButton(false);
      setIsDisabled(false);
      let message = preAuthorization?.message ? preAuthorization?.message : "Card authorization failed."
      openNotificationWithIcon("error", "Error", message)
    }
  }
  const push_to_job_summary = async () => {
    setCreateJobStatus(true);
    await updateJob(jobIdDecline, { status: "Declined" });
    isStripeAvailable()
   //checkCustomerHaveCard(loginUser, customerValid);
  };
  return (
    <>
      <ScheduleJobModal
        isFromMobile={true}
        openScheduleModal={openScheduleModal}
        setOpenScheduleModal={setOpenScheduleModal}
        handelScheduleForLater={handelScheduleForLater}
        isDisabled={isDisabled}
        isRescheduled={isRescheduled}
      />
       <Modal
        className="get-help-now-modal"
        closable={true}
        visible={customerConfirm}
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        maskClosable={true}
        width={800}
        footer={[
          <div className="modal-flex-get-help-now">
            <Button
              className="mobile-dsn-button-css"
              onClick={backToDashBoard}
              key="no"
              disabled={createJobStatus || disableBackToDashbord}
            >
              {disableBackToDashbord ? (
                <Spin size="small" />
              ) : (
                <span>Back To Dashbord</span>
              )}
            </Button>

            <Button
              id="confirm-create-new"
              className="mobile-dsn-button-css"
              onClick={push_to_job_summary}
              key="yes"
              disabled={createJobStatus || disableBackToDashbord}
            >
              {createJobStatus ? (
                <Spin size="small" />
              ) : (
                <span>Create New</span>
              )}
            </Button>
          </div>,
        ]}
      >
        <div className="">
          <span
            className="div-font"
            style={{ fontSize: 20, paddingTop: "40px" }}
          >
            {message}
          </span>
        </div>
      </Modal>
      <div className="sch-time-main-div pl-20-max-width-280 pr-20-max-width-280">
        <div className="d-flex flex-column heading-div">
          <Heading text={"Request a time"} />
          <SubHeading text={"When is a good time for you?"} />
        </div>
        <div className="w-100percent sch-time-div">
          <DatePicker
            isOpen={showDatePicker}
            value={new Date(12345 * 1000)}
            dateConfig={dateConfig}
            theme={"ios"}
            cancelText="Cancel"
            confirmText="OK"
            // onChange={handleDateChange}
            onSelect={handleSelect}
            onCancel={() => setShowDatePicker(false)}
            // min={new Date(2023, 0, 1)}
            min={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours())}
          />
          {
            scheduleJobTimeLoader ?
              // true ?
              <Loader height={"45px"} />
              :
              selectedDateAndTime
                ?
                <>
                  <Alert onClick={() => setShowDatePicker(true)} severity={alertType}>
                    <span>You selected {' '}</span>
                    <span>{selectedDateAndTime[0]}, {selectedDateAndTime[2]}{" "} {selectedDateAndTime[1]} at {selectedDateAndTime[4].substring(0, 5)} </span>
                  </Alert>
                  <div className="d-flex justify-content-center align-items-center gap-8">
                    <ReactSVG src={Globe} />
                    {/* <span className="localTime" >{`${jobInfo.customer.user.timezone} Time Zone (${localScheduleJobTime})` }</span> */}
                    <div className="d-flex flex-column">
                      {/* <span className="localTime text-left" >Geeker Business Hours</span> */}
                      <span className="localTime" >{`Eastern Standard Time (EST): ${localScheduleJobTime}`}</span>
                    </div>
                  </div>
                </>
                :
                <Alert severity={alertTypeForInfo}>
                  <b onClick={() => setShowDatePicker(true)} > Click here to pick a date</b>
                </Alert>
          }
          <Button variant="contained" disabled={disableButton} className="w-100percent sch-btn" onClick={isWithin3HoursForSchedule} >
            {disableButton
              ?
              <Spin className="spinner spinner-pos" />
              :
              <span className="font-nova sch-btn-text">Schedule</span>
            }
          </Button>
        </div>
        <div className="font-nova cancel-sch-job-text-div">
        {(user?.roles[0] !== "owner" && user?.roles[0] !== "admin") ?
          <span>Please make sure to attend your scheduled meeting (note that once a schedule is accepted, your company may incur a small fee for no-shows or last-minute cancellations, so we can keep our technicians happy)</span>
          :
          <span><b> You may cancel up to 3 hours beforehand.</b> if no technician accepted. If the scheduled job was accepted, a $24.99 cancelation fee will be applied.</span>
        }
        </div>
      </div>
    </>
  )
}

export default ScheduleJob;
