import CheckInCircle from "components/CheckInCircle";
import React, { useEffect, useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import wave from "../../../../assets/images/wave.png";
import { getAllPlans } from "../../../../api/subscription.api";
import { useLocation } from "react-router";
import Loader from "../../../../components/Loader";
import * as PublicApi from "../../../../api/public.api";
import * as StripeApi from "../../../../api/stripeAccount.api";

const ChosenPlanSummary = ({
  setchosenProdId,
  setShowSubscriptionPlanModal,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const planId = queryParams.get("planId") ? queryParams.get("planId") : false;
  const [couponId, setCouponId] = useState(
    queryParams.get("couponCode") ? queryParams.get("couponCode") : ""
  );
  const [couponDiscountedPrice, setCouponDiscountedPrice] = useState();
  const [planData, setPlanData] = useState();
  const [planInfo, setPlanInfo] = useState();
  const [
    totalCustomerWithThisSubscrition,
    setTotalCustomerWithThisSubscrition,
  ] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // console.log("Updated state var planData", planData);
    // console.log("Updated state var planInfo", planInfo);
  }, [planData, planInfo]);

  useEffect(() => {
    (async () => {
      // console.log("My console for allPlans 1", planId);
      if (planId) {
        const totalCusotmer = await PublicApi.getTotalCustomerCount({
          "subscription.plan_id": planId,
        });
        // console.log(
        //   "totalCusotmer with current subscription plan ",
        //   totalCusotmer
        // );
        setTotalCustomerWithThisSubscrition(totalCusotmer.totalCount);
        const currentPlan = await PublicApi.getSubscriptionPlan(planId);
        const keyFeatures = currentPlan.data[0].metadata.key_features
          .replace("[", "")
          .replace("]", "")
          .replaceAll(`"`, "")
          .split(",");
        setchosenProdId(planId);
        setPlanInfo(keyFeatures);
        let currentPlanData = (currentPlan.data && currentPlan.data[0] ? currentPlan.data[0] : {});
        setPlanData(currentPlanData);
        setIsLoading(false);
        // console.log("currentPlanData ::: ",currentPlanData)
        // console.log("couponId ::: ",couponId)
        if (couponId !== "" && currentPlanData) {
          const couponCodeInfo = await StripeApi.getCouponInfo({
            id: couponId,
            liveUser: true, //user.customer.customerType === "live",
          });
          
          const discountPercentage = couponCodeInfo.data.percent_off;          
          if (discountPercentage === 100) {          
            setCouponDiscountedPrice(0);
          } else {
            const finalDiscountedPrice =
              (currentPlanData?.price?.unit_amount / 100) *
              ((100 - discountPercentage) / 100);
            setCouponDiscountedPrice(finalDiscountedPrice);
          }
        }
      }
    })();
  }, []);

  const handleChange = () => {
    setShowSubscriptionPlanModal(true);
  };

  if (isLoading) return <Loader height="50%" />;

  return (
    <div className="chosen-plan-summary-div">
      {/* <div className="chosen-plan-summary-cross">
            <FontAwesomeIcon icon={faPlus}/>
        </div> */}
      <div className="mb-25">
        <span className="chosen-plan-summary-head">Your Chosen Plan</span>
      </div>
      <div>
        <span className="small-team-text">{planData?.name}</span>
      </div>
      {/* <div className="mb-25">
            <span className="purchase-number">{totalCustomerWithThisSubscrition > 30 ? totalCustomerWithThisSubscrition : "30"} Purchased this plan!</span>
            <img src={wave} className="wave-img" />
        </div> */}
      {/*queryParams.get("couponCode") && (
        <>
        <div className="mb-25">
          {couponDiscountedPrice &&
            <>
              <span className="actual-price">${planData?.metadata?.reg_price}</span>
              &nbsp;&nbsp;
              <span className="discounted-price">
                ${couponDiscountedPrice}
              </span>
            </>
          }
          {!couponDiscountedPrice &&
            <>
              <span className="actual-price">${planData?.metadata?.reg_price}</span>
                &nbsp;&nbsp;
                <span className="discounted-price">
                  ${planData?.price?.unit_amount / 100}
                </span>
            </>
          }
        </div>
        </>
      )*/}
      {/* <div className="mb-25">
        <span className="actual-price">${planData?.metadata?.reg_price}</span>
        &nbsp;&nbsp;
        <span className="discounted-price">
          ${planData?.price?.unit_amount / 100}
        </span>
      </div> */}
      <div className="mb-25">
        <span className="">${planData?.metadata?.reg_price}</span>
      </div>
      <div className="mb-25">
        {planInfo &&
          planInfo.map((ele) => {
            return (
              <div className="d-flex align-items-center mb-1">
                <CheckInCircle
                  bgColor={"turcose"}
                  style={{ height: "13px", width: "13px" }}
                  checkStyle={{ color: "black" }}
                />
                &nbsp;&nbsp;
                <span className="text-with-check">{ele}</span>
              </div>
            );
          })}
      </div>
      {/* <span className="change-text" onClick={handleChange}>
                Change
        </span> */}
    </div>
  );
};

export default ChosenPlanSummary;
