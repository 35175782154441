import BasicButton from "components/common/Button/BasicButton";
import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, Button } from "react-bootstrap";
import HeadingText from "../Components/HeadingText";
import ProgressBar from "../Components/ProgressBar";
import SubHeadingText from "../Components/SubHeadeingText";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Testimony from "../Components/Testimony";
import ChosenPlanSummary from "../Components/ChosenPlanSummary";
import PasswordInput from "components/AuthComponents/PasswordInput";
import * as AuthApi from "../../../../api/auth.api";
import { useHistory, useLocation } from "react-router";
import { Modal, Spin } from "antd";
import * as CustomerApi from "../../../../api/customers.api";
import {
  INACTIVE_ACCOUNT_STATUS_MSG,
  JOB_STATUS,
  JobTags,
} from "../../../../constants";
import { checkCustomerHaveSubscriptionMinutes, isLiveUser, openNotificationWithIcon, setCookie } from "../../../../utils";
import * as UserApi from "../../../../api/users.api";
import mixpanel from "mixpanel-browser";
import * as JobApi from "../../../../api/job.api";
import * as JobCycleApi from "../../../../api/jobCycle.api";
import { useSocket } from "../../../../context/socketContext";
import { useJob } from "../../../../context/jobContext";
import { useNotifications } from "../../../../context/notificationContext";
import JobInfoRightSideBar from "components/JobInfoRightSideBar";
import { fetchChatOrCreate, checkPendingStatusOfASAP,checkPendingStatusOfSchedule } from "../../../../utils";
import { klaviyoTrack } from "../../../../api/typeService.api";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { useTools } from "../../../../context/toolContext";
import Header from "../Components/Header";

const LogIn = ({
  userInfo,
  setUser,
  setToken,
  setbusinessPlanStepNumber,
  jobFlowStepsObj,
  setchosenProdId,
  setShowSubscriptionPlanModal,
  setShowtwentyPercentModal,
  user,
  job,
}) => {
  const { socket } = useSocket();
  const {setStepDeciderDashboard,setActiveMenu} = useTools();
  const [pwModelVisible, setPwModelVisible] = useState();
  const [password, setPassword] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const planId = queryParams.get("planId") ? queryParams.get("planId") : false;
  const chatMessage = queryParams.get("message") ? queryParams.get("message") : false;
  const jobIdFromUrl = queryParams.get("jobId")
    ? queryParams.get("jobId")
    : false;
  const org_id = queryParams.get("org") ;
  if (org_id) {
    localStorage.setItem('org_id', org_id);
  }
  
  const [showSubscriptionPlanModalTime, setShowSubscriptionPlanModalTime] =
    useState(150000);
  const [discountModalShown, setDiscountModalShown] = useState(false);
  let liveUser = isLiveUser(user);
  const { updateJob } = useJob();
  const { createNotification, fetchNotifications } = useNotifications();
  const technicianId = queryParams.get("technicianId")
    ? queryParams.get("technicianId")
    : false;
  const couponCode = queryParams.get("couponCode")
    ? queryParams.get("couponCode")
    : false;
  const [customerConfirm, setCustomerConfirm] = useState(false);
  const [scheduleMsg, setScheduleMsg] = useState(false);
  const [lastPendingSoftware, setLastPendingSoftware] = useState("");
  const [latestPendingJobToUpdate, setLatestPendingJobToUpdate] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [loginUser, setLoginUser] = useState();
  const [jobIdDecline, setJobIdDecline] = useState(false);
  const [pendingJobModal, setPendingJobModal] = useState(false);
  const [createJobStatus, setCreateJobStatus] = useState(false);
  const [customerValid, setCustomerValid] = useState(false);
  const [inProgressMessage, setInProgressMessage] = useState("");

  const InprogressJobMsg = pendingJobModal ? (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      {inProgressMessage}
    </span>
  ) : (
    <span></span>
  );

  const message = scheduleMsg ? (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      One of your previous job of{" "}
      <b style={{ fontWeight: "bold" }}>{lastPendingSoftware}</b> is already
      scheduled with a technician. Are you sure you want to create a new job
      post?if yes, then your previous job will be{" "}
      <b style={{ fontWeight: "bold" }}>Cancelled</b>
    </span>
  ) : (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      We are still looking for a technician for your existing job of{" "}
      <b style={{ fontWeight: "bold" }}>{lastPendingSoftware}</b>. Are you sure
      you want to create a new job post? if yes, then your previous job will be{" "}
      <b style={{ fontWeight: "bold" }}>Cancelled</b>
    </span>
  );
  useEffect(() => {
    var isScheduleJobValue = localStorage.getItem("isScheduleJob");
  
    if (!discountModalShown) {
      if (planId) {
        setTimeout(() => {
          setShowtwentyPercentModal(true);
          setDiscountModalShown(true);
        }, showSubscriptionPlanModalTime);
      }
    }
  }, [showSubscriptionPlanModalTime]);

  const resetPasswordConfirmationModal = useCallback(() => {
    setShowSubscriptionPlanModalTime(150000);
    Modal.confirm({
      title: "Reset Password ?",
      okText: "Yes",
      cancelText: "No",
      className: "reset-password-confirmation-modal",
      onOk() {
        setbusinessPlanStepNumber(jobFlowStepsObj["ResetPasswordLink"]);
      },
    });
  }, []);

  // useEffect(() => {
  // console.log("Lofin user infor>>>>>>>>", loginUser);
  // }, [loginUser]);
  /**
   * Following function is to handle back button click
   * @params : none
   * @return : none
   * @author : Mritunjay Chaurasia
   **/
  const handleBackBtnClicked = () => {
    setbusinessPlanStepNumber(jobFlowStepsObj["SignIn"]);
  };

  /**
   * Following function handle user login
   * @params = null
   * @response : null
   * @author : Vinit
   */
  const handleLogIn = async () => {
    setShowSubscriptionPlanModalTime(150000);
    setDisableBtn(true);
    // localStorage.setItem("mobile", "true");
    const loginResponse = await AuthApi.login({
      email: userInfo.email,
      password: password,
    });
    // console.log(
    //   "My console for loginResponse from business plan",
    //   loginResponse
    // );
    if (loginResponse && loginResponse.success) {
      openNotificationWithIcon(
        "success",
        "Success",
        "User logged in successfully!"
      );
      setIncorrectPassword(false);
      setToken(loginResponse.token.accessToken);
      setCookie(loginResponse?.user)
      // setUser(loginResponse.user)
      if (loginResponse?.user?.userType === "customer") {
        if (jobIdFromUrl) {
          const customerTypeValid = loginResponse.user.customer.customerType
            ? loginResponse.user.customer.customerType !== "test"
            : true;
          
          setCustomerValid(customerTypeValid);
          checkPendingJobs(loginResponse.user, customerTypeValid);
          setLoginUser(loginResponse.user);
        } else {
          if (planId) {
            if (couponCode) {
              window.location.href = `/buy-business-plan?planId=${planId}&page=CompleteYourPurchase&couponCode=${couponCode}`;
            } else {
              window.location.href = `/buy-business-plan?planId=${planId}&page=CompleteYourPurchase`;
            }
          } else {
            //Checking if customer already have card added to account.
            if (loginResponse.user) {
              let liveUser = await isLiveUser(loginResponse.user);
              let customer_info =
                await CustomerApi.checkIfOrganisationHasSubscription({
                  user: loginResponse.user,
                  liveUser: liveUser,
                });
              // console.log("My console for customer_info", customer_info);
              if (customer_info.has_card_or_subscription) {
                if (chatMessage) {
                  window.location.href = `/dashboard?message=${chatMessage}`;
                }else{
                  window.location.href = `/`;
                }
              } else {
                window.location.href = `/customer/registered`;
              }

               // if (chatMessage) {
              //   console.log("inside the message when login 1", chatMessage);
              //   setStepDeciderDashboard(15);
              //   setActiveMenu("messages");
              // }
            }
          }
        }
      }
    } else {
      openNotificationWithIcon("error", "Error", "Incorrect Password");
      setDisableBtn(false);
      setIncorrectPassword(true);
    }
  };

  const checkCustomerHaveCard = async (user, customerTypeValid) => {
    try {
      if (user && !user?.activeStatus) {
        openNotificationWithIcon("info", "Info", INACTIVE_ACCOUNT_STATUS_MSG);
        await updateJob(jobIdFromUrl, {
          customer: user?.customer?.id,
          guestJob: false,
          cardPreAuthorization: false,
        });
        const timer = setTimeout(() => {
          history.push("/dashboard");
        }, 2000);
        return () => clearTimeout(timer);
      }
      if (user && user.customer) {
        mixpanel.identify(user.email);
        const ownerId = user?.ownerId;
        // console.log("owner iiddddd :::::::", ownerId);
        let ownerStripeId = "";
        if (ownerId) {
          const ownerUserDetails = await UserApi.getUserById(ownerId);
          // console.log("ownerUserDetails ::::", ownerUserDetails);
          if (ownerUserDetails?.customer?.stripe_id) {
            ownerStripeId = ownerUserDetails?.customer?.stripe_id;
          } else {
            openNotificationWithIcon(
              "info",
              "Info",
              `Please contact your organization owner to add card to proceed with this job!`
            );
            history.push("/");
            return;
          }
        }
  
        const data = await JobApi.latestpendingJobs({
          customer: user?.customer?.id,
        });
        
        const scheduleJobsArray = data?.statusJobs?.find(entry => entry.status === 'Scheduled')?.jobs || [];
  
        let primaryScheduleTimes = [];
        scheduleJobsArray.forEach((job) => {
  // Access the primarySchedule property of each object and push it to the primarySchedules array
  
  primaryScheduleTimes.push(job.primarySchedule);
        });
       
        // console.log('checkCustomerHaveCard data and currentJob', job);
         console.log('checkCustomerHaveCard scheduleJobTime api>>>>>>', primaryScheduleTimes[0]);
        // console.log('checkCustomerHaveCard', job?.primarySchedule);

        // Call the function to check and handle date match
        const isMatch= checkAndHandleDateMatchForSchedule(
          primaryScheduleTimes,
          job?.primarySchedule,
          jobIdFromUrl,
          user
        );
        if (isMatch) {
          openNotificationWithIcon(
            "error",
            "Error",
            "One of your jobs is already scheduled with the same date and time. Please pick any other time to schedule a new job"
          );
          JobApi.updateJob(jobIdFromUrl, {
            status: "Draft",
            customer: user?.customer?.id,
            guestJob: false,
            cardPreAuthorization: false,
          });
    
          setTimeout(() => {
            window.location.href = `/customer/start-profile-setup?page=job-summary&jobId=${jobIdFromUrl}`;
          }, 2000);
          return
        }
        let customer_info =
          await CustomerApi.checkIfOrganisationHasSubscription({
            user: user,
            liveUser: user.customer.customerType === "live",
          });
        if (
          customer_info.has_card_or_subscription === false &&
          customerTypeValid
        ) {
          // mixpanel code//
          mixpanel.track("Customer - Ask Credit Card");
          mixpanel.people.set({
            $first_name: user.firstName,
            $last_name: user.lastName,
          });
          // mixpanel code//
          const updatedJOb = await updateJob(jobIdFromUrl, {
            customer: user.customer.id,
            guestJob: false,
            cardPreAuthorization: false,
          });
          // console.log("My console for updatedJob", updatedJOb);
          window.location.href = `/customer/profile-setup?page=add-card&jobId=${jobIdFromUrl}`;
        } else {
          let paidJobs = await JobApi.getTotalJobs({
            customer: user?.customer?.id,
          });
          if (paidJobs >= 1) {
            await JobApi.updateJob(jobIdFromUrl, { isReturningCustomer: true });
          }
          const stripeId = user?.ownerId
            ? ownerStripeId
            : user?.customer?.stripe_id;

          // holdPaymentWhileSubscription variable check if there is any subscription purchased by customer owner and have subscription minutes to zero then we will charge to customer
          let checkCardAndHoldPayment = false;
          if (user?.customer) {
            checkCardAndHoldPayment =
              await checkCustomerHaveSubscriptionMinutes(user?.customer);
          }
          console.log("checkCardAndHoldPayment",{checkCardAndHoldPayment : user?.customer})
          let preauthorize ={}
          if (checkCardAndHoldPayment) {
            preauthorize = await CustomerApi.holdChargeFromCustomer({
              stripe_id: stripeId,
              liveUser:
                user?.customer?.customerType &&
                user?.customer?.customerType === "live"
                  ? true
                  : false,
              jobId: jobIdFromUrl,
            });
          }
          // console.log("My console for preaut", preauthorize);
          if (preauthorize.status === "Successful" || !checkCardAndHoldPayment ) {
            if(checkCardAndHoldPayment){
              await JobCycleApi.create(JobTags.HOLD_CHARGE, jobIdFromUrl);
              mixpanel.track("Customer - Preauthorize successfully", {
                jobId: jobIdFromUrl,
              });
            }
            if (localStorage.getItem("isScheduleJob") === true || localStorage.getItem("isScheduleJob") === 'true') {
              localStorage.removeItem("isScheduleJob");
              // console.log("inside preauthorize schedule if");
              const updatedJob = await JobApi.updateJob(jobIdFromUrl, {
                status: "Scheduled",
                customer: user.customer.id,
                guestJob: false,
                cardPreAuthorization: true,
                payment_type : checkCardAndHoldPayment ? "card_only" :"subscription_only"
              });
              // createUpdateJob("Scheduled")
              if (updatedJob && user) {
                await fetchChatOrCreate(updatedJob, user);
              }
              await emitSocketCreateFetchNotification(updatedJob, user);

              setTimeout(() => {
                window.location.href =
                  "/dashboard?&scheduleJobId=" + jobIdFromUrl;
              }, 500);
              setDisableBtn(false);
            } else {
              // console.log("inside preauthorize ASAP else");
              try {
                const testingRes = await JobApi.updateJob(jobIdFromUrl, {
                  status: "Pending",
                  customer: user.customer.id,
                  guestJob: false,
                  cardPreAuthorization: true,
                  tech_search_start_at: new Date(),
                  payment_type : checkCardAndHoldPayment ? "card_only" :"subscription_only"
                });
                if (testingRes && user) {
                  await fetchChatOrCreate(testingRes, user);
                }
                // console.log("window.location.href from SignIn", testingRes);
                window.location.href = `/customer/profile-setup?page=tech-search&jobId=${jobIdFromUrl}`;
              } catch (error) {
                console.error("Error:", error);
              }
            }
          } else {
            await JobCycleApi.create(JobTags.HOLD_CHARGE_FAIL, jobIdFromUrl);
            let mixpanelData = {};
            if (job && job.id) {
              mixpanelData = { jobId: job.id };
            }
            mixpanel.track("Customer - Preauthorize Failed", mixpanelData);
            if (job && job.id && job.guestJob && user && user.customer) {
              await updateJob(job.id, {
                customer: user.customer.id,
                guestJob: false,
              });
            }
            let timeOutDuration = 2000;
            if (
              preauthorize?.response?.decline_code === "invalid_account" ||
              preauthorize?.message.includes("Invalid account")
            ) {
              timeOutDuration = 4000;
              openNotificationWithIcon(
                "error",
                "Error",
                "Unable to authorize your existing credit card. Please add new credit card from Settings -> Card Details."
              );
            } else {
              openNotificationWithIcon("error", "Error", preauthorize.message);
            }

            setTimeout(() => {
             window.location.href = "/dashboard";
            }, timeOutDuration);
          }
          // }
        }
      }
    } catch (err) {
      console.log("error in checkForCard", err);
    }
  };

  function checkAndHandleDateMatchForSchedule(
    primaryScheduleTimes,
    primarySchedule,
    jobIdFromUrl,
    user
  ) {
    const scheduleObj = new Date(primarySchedule);
  
    const isMatch = primaryScheduleTimes.some((date) => {
      const dateObj = new Date(date);
     

      // Compare date, hours (in 12-hour format), minutes, and AM/PM
      return (
        dateObj.getDate() === scheduleObj.getDate() &&
        dateObj.getHours() === scheduleObj.getHours() &&
        dateObj.getMinutes() === scheduleObj.getMinutes() &&
        (dateObj.getHours() >= 12 ? "PM" : "AM") ===
          (scheduleObj.getHours() >= 12 ? "PM" : "AM")
      );
    });
    return isMatch
  
  }

  /**
   * Function will check if there are any pending jobs of the customer else it will call push_to_profile_setup function to find technician.
   * @author : Nafees
   */
  const checkPendingJobs = async (loginUser, customerTypeValid) => {
    try {
      // console.log("Checking pending jobs data: ", loginUser);
      if (loginUser && loginUser?.customer) {
        const latestpendingJobs = await JobApi.latestpendingJobs({
          customer: loginUser.customer.id,
        });
        // console.log("Checking latest pending pob ::", latestpendingJobs);
        setLatestPendingJobToUpdate(latestpendingJobs);

        let isScheduleJobValue = localStorage.getItem("isScheduleJob");
        let pendingJobs;
        if(isScheduleJobValue==="true" ||isScheduleJobValue===true){
          pendingJobs=await checkPendingStatusOfSchedule(loginUser)
   
        }else{
          pendingJobs=await checkPendingStatusOfASAP(loginUser)
        }        
        // console.log("Checking pending jobs ::", pendingJobs);
        if (pendingJobs.schedule_accepted) {
          setScheduleMsg(true);
        }
       
      
        if (pendingJobs.success) {
          setLastPendingSoftware(pendingJobs.name);
          if (latestpendingJobs?.acceptedCount >0){
          	setPendingJobModal(true);
          	setInProgressMessage(
          		<span className="div-font" style={{ fontSize: 20, paddingTop: '40px' }}>
          		One of your job of <b style={{ fontWeight: 'bold' }}>{latestpendingJobs?.last_pending_job?.software.name}</b> is already <b style={{ fontWeight: 'bold' }}>{latestpendingJobs?.last_pending_job?.status}</b> with a technician. Please complete that job first then post a new job.
          	  </span>
          	  );
            }else{
        
          setCustomerConfirm(true);
            }
        } else {
          checkCustomerHaveCard(loginUser, customerTypeValid);
        }
      }
    } catch (e) {
      console.log("Error in checkPendingJobs ", e);
    }
  };
  const closePendingModal = () => {
    setCustomerConfirm(false);
    setPendingJobModal(false);
  };
  const push_to_job_summary = async () => {
    setCreateJobStatus(true);
    const desiredStatuses = ["Pending", "Waiting"];

    // Filter 'statusJobs' array to include only desired statuses
   
     
   
            let lastPendingJob;
            let scheduleJobsArray=[]
            let pendingJobsArray
            let isScheduleJobValue = localStorage.getItem("isScheduleJob");
      if (isScheduleJobValue==="true" ||isScheduleJobValue===true) {
         scheduleJobsArray = latestPendingJobToUpdate?.statusJobs?.find(entry => entry.status === 'Scheduled')?.jobs ;
   
          lastPendingJob = scheduleJobsArray[0];
    }
    else{
       pendingJobsArray=(latestPendingJobToUpdate?.statusJobs?.filter(entry => desiredStatuses.includes(entry.status)))

       lastPendingJob = pendingJobsArray[0].jobs[0];
       console.log("inside the latestJob1212::", lastPendingJob);
    }
    if (
      lastPendingJob &&
      (lastPendingJob.status === JOB_STATUS.PENDING ||
        lastPendingJob.status === JOB_STATUS.WAITING ||
        lastPendingJob.status === JOB_STATUS.SCHEDULED)
    ) {
      console.log("inside the latestJob updateJob job :::::");
      const updatedJob = await updateJob(lastPendingJob._id, {
        status: "Declined",
      });
      console.log(
        "inside the latestJob updateJob job ::::: 2",
        updatedJob
      );
      }
    checkCustomerHaveCard(loginUser, customerValid);
    // const updateJobData = {
    //     cardPreAuthorization: false,
    //     customer: loginUser?.customer?.id,
    //     guestJob:false
    //   };
    // const updateJobCurrent = await updateJob(jobIdFromUrl, updateJobData)
    // window.location.href = `/customer/profile-setup?page=job-summary&jobId=${jobIdFromUrl}`;
  };

  const backToDashBoardStatus = async () => {
    const updateJobData = {
      cardPreAuthorization: false,
      customer: loginUser?.customer?.id,
      guestJob: false,
    };
    await updateJob(jobIdFromUrl, updateJobData);
    window.location.href = "/dashboard";
  };

  const backToDashBoard = async () => {
    const updateJobData = {
      cardPreAuthorization: false,
      customer: loginUser?.customer?.id,
      guestJob: false,
    };
    await updateJob(jobIdFromUrl, updateJobData);
    window.location.href = "/dashboard";
  };

  /**
   * emit send-schedule-alerts socket and create / fetch notification customer notifications
   * @params : jobStats(Type:Object): Have job details
   * @returns : null
   * @author : Ridhima Dhir
   */
  const emitSocketCreateFetchNotification = async (jobStats, user) => {
    try {
      // console.log("send-schedule-alerts :::::::::::");
      //Notification for customer
      const notificationData = {
        user: user.id,
        job: jobStats.id,
        read: false,
        actionable: false,
        title:
          "We are finding a technician for you. We will inform you when we find the technician",
        type: "Scheduled Job",
      };
      // console.log("notificationData ::::::::", notificationData);
      await createNotification(notificationData);
      console.log("fetchNotifications() :: called emitSocketCreateFetchNotification function in login.ts");
      await fetchNotifications({ user: user.id });

      // call send-schedule-alerts socket from backend.
      // It will find available techs and send alerts by sms/email/notification
      socket.emit("search-for-tech", {
        jobId: jobStats.id,
        customerTimezone: user.timezone,
        jobData: jobStats,
        primaryTime: jobStats.primarySchedule,
        phoneNumber: user.customer.phoneNumber,
        customerName: user.firstName,
        customerEmail: user.email,
        technicianId: technicianId ? technicianId : false,
      });
    } catch (err) {
      console.log(
        "There is catch error while create/fetch notification  :::: " +
          err.message
      );
      mixpanel.identify(user.email);
      mixpanel.track("There is catch error while create/fetch notification", {
        jobStats: jobStats,
        errMessage: err.message,
      });
    }
  };

  return (
    <>

      <Modal
        className="get-help-now-modal"
        closable={true}
        onCancel={closePendingModal}
        visible={pendingJobModal}
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        maskClosable={true}
        width={800}
        footer={[
          <div className="modal-flex-get-help-now">
            <Button
              className="btn app-btn app-btn-light-blue modal-footer-btn"
              onClick={backToDashBoardStatus}
              key="no"
            >
              <span></span>Back To Dashbord
            </Button>
          </div>,
        ]}
      >
        <div className="">
          <span
            className="div-font"
            style={{ fontSize: 20, paddingTop: "40px" }}
          >
            {InprogressJobMsg}
          </span>
        </div>
      </Modal>
      <Modal
        className="get-help-now-modal"
        closable={true}
        visible={customerConfirm}
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        maskClosable={true}
        width={800}
        footer={[
          <div className="modal-flex-get-help-now">
            <Button
              className="btn app-btn app-btn-light-blue modal-footer-btn"
              onClick={backToDashBoard}
              key="no"
            >
              <span></span>Back To Dashbord
            </Button>

            <Button
              id="confirm-create-new"
              className="btn app-btn job-accept-btn modal-footer-btn"
              onClick={push_to_job_summary}
              key="yes"
              disabled={createJobStatus}
            >
              <span></span>Create New
              {createJobStatus && (
                <Spin size="small" style={{ marginLeft: "10px" }} />
              )}
            </Button>
          </div>,
        ]}
      >
        <div className="">
          <span
            className="div-font"
            style={{ fontSize: 20, paddingTop: "40px" }}
          >
            {message}
          </span>
        </div>
      </Modal>
      <div className="custom-container  min-height-inherit">
        <Header/>
        <Row className="min-height-inherit d-flex justify-content-center align-items-center parent-row">
          <Col md={9} xs={12} className="d-flex flex-column min-height-inherit">
            {planId && <ProgressBar currentStep={1} />}
            <div className="d-flex flex-column justify-content-center align-items-center min-height-inherit">
              <div className="mb-50">
                <HeadingText firstBlackText={`Welcome back, ${userInfo?.firstName}`} />
              </div>
              <div className="choose-password-email-div d-flex justify-content-center align-items-center mb-20">
                <span className="choose-password-email">{userInfo?.email}</span>
              </div>
              {/* <form onSubmit={handleLogIn}> */}
              <div className="mb-20 w-428 max-width-768-w-265px position-relative">
                <SubHeadingText text={"Password"} />
                <PasswordInput
                  name="Password"
                  placeholder="Password"
                  type="password"
                  onFocus={() => setPwModelVisible(true)}
                  onBlur={() => setPwModelVisible(false)}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <a onClick={resetPasswordConfirmationModal}>
                  <SubHeadingText text={"Forgot Password?"} />
                </a>
              </div>
              <div className="business-plan-sign-in-button mb-15 d-flex flex-row justify-content-between">
                <BasicButton
                  btnType={"button"}
                  disable={disableBtn}
                  onClick={handleBackBtnClicked}
                  height={"70px"}
                  width={"75px"}
                  background={"#92A9B8"}
                  color={"#fff"}
                  btnIcon={"arrow"}
                  faFontSize={"18px"}
                  arrowDirection={"left"}
                />

                <BasicButton
                  btnType={"button"}
                  btnTitle={"Log In"}
                  height={"70px"}
                  width={"175px"}
                  background={"#01D4D5"}
                  color={"white"}
                  btnIcon={"arrow"}
                  faFontSize={"18px"}
                  arrowDirection={"right"}
                  onClick={handleLogIn}
                  disable={disableBtn}
                  showSpinner={disableBtn}
                />
              </div>
              {/* </form> */}
              {incorrectPassword && (
                <div className="d-flex align-items-center">
                  <div className="login-info-round-div mr-10">
                    <FontAwesomeIcon
                      icon={faInfo}
                      className="business-plan-info-icon"
                    />
                  </div>
                  <span className="tAndc-text grey-color-text">
                    Incorrect Password
                  </span>
                </div>
              )}
            </div>
          </Col>
          <Col md={3} xs={12} className="sign-in-side-column">
            {planId && (
              <ChosenPlanSummary
                setchosenProdId={setchosenProdId}
                setShowSubscriptionPlanModal={setShowSubscriptionPlanModal}
              />
            )}
            {jobIdFromUrl && <JobInfoRightSideBar user={user} />}
            <Testimony testimonyBy={"jennifer"} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LogIn;
